import Container from 'components/common/Container';
import Label from 'components/common/Label';
import Section from 'components/common/Section';
import React from 'react';
import ResidenIcon from 'assets/home/industries/real-estate.svg';
import RetailIcon from 'assets/home/industries/retail.svg';
import BuildingIcon from 'assets/home/industries/building.svg';
import CorporateIcon from 'assets/home/industries/corporate.png';
import FinanceIcon from 'assets/home/industries/finance.svg';
import ExecutiveIcon from 'assets/home/industries/executive.svg';
import PropertyIcon from 'assets/home/industries/property.svg';
import AccountingIcon from 'assets/home/industries/new/accounting.svg';
import clsx from 'clsx';
import LinkItem from './LinkItem';
import { Link } from 'react-router-dom';
import SliderIndustries from './SliderIndustries';

const Industries = () => {
  const dataArr = [
    {
      icon: AccountingIcon,
      title: 'Accounting',
      className: '',
      order: 'order-[1]'
    },
    {
      icon: RetailIcon,
      title: 'Residential and Commercial Real Estate ',
      className: 'bg-brand-other-surface ',
      order: 'order-[2]'
    },
    {
      icon: CorporateIcon,
      title: 'Body Corporate',
      className: '',
      order: 'order-[3]'
    },
    {
      icon: FinanceIcon,
      title: 'Finance and Investment ',
      className: 'bg-brand-other-surface ',
      order: 'order-[4]'
    },
    {
      icon: BuildingIcon,
      title: 'Building and Construction ',
      className: 'bg-brand-other-surface ',
      order: 'order-[7] xl:order-[5]  col-start-2 xl:col-start-1'
    },
    {
      icon: PropertyIcon,
      title: 'Property Development',
      className: '',
      order: 'order-[5] xl:order-[6]'
    },
    {
      icon: ExecutiveIcon,
      title: 'Executive Search',
      className: 'bg-brand-other-surface ',
      order: 'order-[6] xl:order-[7]'
    }
  ];
  return (
    <Section id="industries" className=" py-12 sm:pb-[64px] sm:pt-[64px] xl:pb-[96px]">
      <Container>
        <div className="grid grid-cols-4 gap-x-8 sm:grid-cols-12">
          <div className="col-span-full mb-[40px] sm:col-span-6 sm:col-start-4 sm:mb-[72px] xl:col-span-4 xl:col-start-5 xl:mb-[64px]">
            <div className="mx-auto flex w-full max-w-[410px] flex-col items-center">
              <h2 data-aos="fade-up" data-aos-delay="400" className="text-head-four font-medium text-black-100 sm:text-head-three xl:text-head-two ">
                What we cover{' '}
              </h2>
            </div>
          </div>
        </div>
        <div id="industries-list" className={clsx('hidden sm:grid', `grid-cols-3 xl:grid-cols-4`)}>
          <LinkItem data={dataArr[0]} order={dataArr[0].order} data-aos="zoom-in" data-aos-delay="500" />
          <LinkItem data={dataArr[1]} order={dataArr[1].order} data-aos="zoom-in" data-aos-delay="700" />
          <LinkItem data={dataArr[2]} order={dataArr[2].order} data-aos="zoom-in" data-aos-delay="900" />
          <LinkItem data={dataArr[3]} order={dataArr[3].order} data-aos="zoom-in" data-aos-delay="1100" />
          <LinkItem data={dataArr[4]} order={dataArr[4].order} data-aos="zoom-in" data-aos-delay="1300" />
          <LinkItem data={dataArr[5]} order={dataArr[5].order} data-aos="zoom-in" data-aos-delay="1500" />
          <LinkItem data={dataArr[6]} order={dataArr[6].order} data-aos="zoom-in" data-aos-delay="1700" />
          <Link
            data-aos="zoom-in"
            data-aos-delay="1800"
            to="/start-hiring"
            className={clsx('order-[8] hidden h-[300px] flex-col items-start justify-center p-8 xl:flex')}>
            <p className="font-ranade text-large font-medium">View More</p>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 18L18 6M18 6H10M18 6V14" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Link>
        </div>
        <SliderIndustries />
      </Container>
    </Section>
  );
};

export default Industries;
