import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import React, { useEffect, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Keyboard, Pagination } from 'swiper';
import Skeleton from './Skeleton';
import { Link } from 'react-router-dom';

import useSwiperRef from 'utils/useSwiperRef';
import { clientRoot } from 'api';
import useResponsive from 'utils/useResponsive';
const SliderJobs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [jobs, setJobs] = useState(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']);
  // const [countries, setCountries] = useState([]);
  const [nextEl, nextElRef] = useSwiperRef();
  const [prevEl, prevElRef] = useSwiperRef();
  const { sm } = useResponsive();
  const sliceJobs = () => {
    if (sm) {
      return jobs;
    } else {
      return jobs?.slice(0, 3);
    }
  };
  useEffect(() => {
    clientRoot.get('jobs?per_page=100&published_at_sort=desc&status=active').then((response) => {
      setJobs(response.data.results);
      setIsLoading(false);
      console.log(response.data.results);
    });

    clientRoot.get('countries').then((response) => {
      setCountries(response.data.countries);
    });
  }, []);
  const getCountry = (arr, code) => {
    const country = arr.filter((item) => {
      return item.code === code;
    })[0]?.name;
    return country;
  };
  // console.log('jobs', jobs);
  return (
    <div className="relative mb-8 xl:mb-12 " data-aos="zoom-in" data-aos-delay="900">
      <Swiper
        id="jobs-slider"
        className="w-full overflow-x-hidden"
        // style={{
        //   overflow: 'visible'
        // }}
        pagination={{
          el: '.job-pagination',
          clickable: true,
          renderBullet: (index, className) => {
            return '<span class="' + className + '"></span>';
          }
        }}
        spaceBetween={32}
        slidesPerView={1}
        centeredSlides={true}
        onSwiper={(swiper) => console.log(swiper)}
        navigation={{ prevEl, nextEl, clickable: true }}
        keyboard={true}
        loop={true}
        speed={1000}
        autoplay={{
          delay: 4000,
          disableOnInteraction: true
        }}
        modules={[Autoplay, Navigation, Keyboard, Pagination]}
        breakpoints={{
          640: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 3
          },
          1280: {
            slidesPerView: 3
          }
        }}>
        {isLoading ? (
          <>
            <SwiperSlide>
              <Skeleton />
            </SwiperSlide>
            <SwiperSlide>
              <Skeleton />
            </SwiperSlide>
            <SwiperSlide>
              <Skeleton />
            </SwiperSlide>
            <SwiperSlide>
              <Skeleton />
            </SwiperSlide>
            <SwiperSlide>
              <Skeleton />
            </SwiperSlide>
          </>
        ) : (
          sliceJobs().map((job, index) => {
            console.log('job', job);
            const { id, title, city, country_code: countryCode, owners, category, salary } = job;
            return (
              <SwiperSlide key={index}>
                <div className="w-full max-w-[379px] bg-black-10 px-5 py-6 xl:p-8 ">
                  <div className="mb-6 flex items-center justify-between">
                    <div className="h-[64px] w-[64px] overflow-hidden rounded-full">
                      <img src={owners?.length ? owners[0]?.avatar_original_url : '-'} alt="Person" className="h-full w-full" />
                    </div>
                    <label htmlFor="full-time" className="bg-brand-primary-surface px-4 py-1">
                      <p className="text-small font-medium text-black-100">Full Time</p>
                    </label>
                  </div>

                  <div className="flex flex-col">
                    <h6 className="mb-2 text-left text-large font-medium">{title}</h6>
                    <p className="hidden text-small text-black-60 xl:block">
                      <span className="text-[#aaaaaa]">By </span>
                      {owners?.length ? owners[0]?.name : '-'}
                    </p>
                    <div className="flex flex-col gap-y-1 xl:hidden">
                      <p className="text-small text-black-60 ">
                        {' '}
                        <span className="text-[#aaaaaa]">By </span>
                        {owners?.length ? owners[0]?.name : '-'}{' '}
                      </p>
                    </div>
                    <div className="mt-4 border-t border-black-30 pt-4">
                      <div className="flex flex-col">
                        <div className="mb-3 flex">
                          <div className="mr-2 flex w-full max-w-[80px]">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="mr-2" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M7.33337 7.33333H4.13337C3.38664 7.33333 3.01327 7.33333 2.72805 7.47866C2.47717 7.60649 2.2732 7.81046 2.14537 8.06135C2.00004 8.34656 2.00004 8.71993 2.00004 9.46667V14M14 14V4.13333C14 3.3866 14 3.01323 13.8547 2.72801C13.7269 2.47713 13.5229 2.27316 13.272 2.14532C12.9868 2 12.6134 2 11.8667 2H9.46671C8.71997 2 8.3466 2 8.06139 2.14532C7.8105 2.27316 7.60653 2.47713 7.4787 2.72801C7.33337 3.01323 7.33337 3.3866 7.33337 4.13333V14M14.6667 14H1.33337M9.66671 4.66667H11.6667M9.66671 7.33333H11.6667M9.66671 10H11.6667"
                                stroke="#3D3D3D"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <p className="text-small font-medium text-black-90">Sector</p>
                          </div>
                          <p className="truncate text-small font-medium  text-black-60">: {category?.name ? category.name : '-'} </p>
                        </div>
                        <div className="flex">
                          <div className="mr-2 flex w-full max-w-[80px]">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="mr-2" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M4.00004 7.33341V10.0001M12 6.00008V8.66675M11.3334 2.66675C12.9658 2.66675 13.8488 2.91659 14.2881 3.11038C14.3466 3.13618 14.3759 3.14909 14.4603 3.22966C14.5109 3.27796 14.6033 3.41968 14.6271 3.48547C14.6667 3.59524 14.6667 3.65524 14.6667 3.77524V10.9408C14.6667 11.5467 14.6667 11.8496 14.5759 12.0053C14.4834 12.1637 14.3943 12.2373 14.2213 12.2982C14.0513 12.358 13.708 12.2921 13.0215 12.1602C12.541 12.0679 11.9711 12.0001 11.3334 12.0001C9.33337 12.0001 7.33337 13.3334 4.66671 13.3334C3.03424 13.3334 2.15129 13.0836 1.71196 12.8898C1.65346 12.864 1.6242 12.8511 1.53977 12.7705C1.48916 12.7222 1.39679 12.5805 1.37302 12.5147C1.33337 12.4049 1.33337 12.3449 1.33337 12.2249L1.33337 5.05932C1.33337 4.45347 1.33337 4.15055 1.42423 3.99484C1.51664 3.83646 1.60577 3.76283 1.77875 3.70196C1.9488 3.64212 2.29206 3.70807 2.97859 3.83998C3.45912 3.93231 4.02903 4.00008 4.66671 4.00008C6.66671 4.00008 8.66671 2.66675 11.3334 2.66675ZM9.66671 8.00008C9.66671 8.92055 8.92052 9.66675 8.00004 9.66675C7.07957 9.66675 6.33337 8.92055 6.33337 8.00008C6.33337 7.07961 7.07957 6.33341 8.00004 6.33341C8.92052 6.33341 9.66671 7.07961 9.66671 8.00008Z"
                                stroke="#3D3D3D"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>

                            <p className="text-small font-medium text-black-90">Salary</p>
                          </div>
                          <p className="truncate text-small font-medium text-black-60">: {salary || '-'}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Link
                    to={`/job/${id}`}
                    className="mt-8 flex h-[56px] w-full items-center justify-center border border-black-100 font-ranade text-medium font-medium text-black-100">
                    Learn More
                  </Link>
                </div>
              </SwiperSlide>
            );
          })
        )}
      </Swiper>
      <div className="job-pagination  mt-8 flex justify-center sm:hidden"></div>
      <button
        ref={prevElRef}
        className="absolute left-[-30px] top-[39%] z-[1] hidden h-[48px] w-[48px] items-center justify-center rounded-full bg-white shadow-slider-nav sm:flex xl:-left-[71px]">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.5 19L8.5 12L15.5 5" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>
      <button
        ref={nextElRef}
        className="absolute right-[-30px] top-[39%] z-[1] hidden h-[48px] w-[48px] items-center justify-center rounded-full bg-white shadow-slider-nav sm:flex xl:-right-[71px]">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.5 19L15.5 12L8.5 5" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>
    </div>
  );
};

export default SliderJobs;
