import React from 'react';
import PropTypes from 'prop-types';

const InputGroup = ({ name, isRequired, placeholder, type, label, value, onChange, icon }) => {
  return (
    <div className="flex w-full flex-col ">
      <label className="mb-2 text-xsmall font-medium text-black-100">
        {label}
        {isRequired && <span className="text-danger-500">*</span>}
      </label>
      <div className="relative">
        {icon}
        <input
          type={type}
          name={name}
          className="w-full rounded-lg border border-transparent bg-neutral-10 py-[12px] pl-14 pr-4 text-small font-medium text-black-100 placeholder:text-small  placeholder:font-medium placeholder:tracking-wide placeholder:text-neutral-40 focus:border-brand-primary-main focus:ring-0 sm:text-base sm:placeholder:text-base"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required={isRequired}
        />
      </div>
    </div>
  );
};

InputGroup.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default InputGroup;
