import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';

import Role from './Role';
import Location from './Location';

import JobCardSkeleton from '../Skeleton/JobCardSkeleton';

import PersonImage from 'assets/jobs/person.png';
import { clientRoot } from 'api';

// image
import { ReactComponent as JobIcon } from 'assets/jobs/building.svg';
import { ReactComponent as SalaryIcon } from 'assets/jobs/bank.svg';
import { ReactComponent as PlaceIcon } from 'assets/jobs/marker.svg';

const JobCard = ({ index, data }) => {
  const { id, title, city, category, published_at: datePublished, salary, agency, owners } = data;

  const [isLoading, setIsLoading] = useState(true);
  const [description, setDescription] = useState();

  const childRef = useRef(null);

  useEffect(() => {
    clientRoot.get(`job?id=${data.id}`).then((response) => {
      setDescription(response.data.description);
      setIsLoading(false);
    });
    // console.log('Data', data);
  }, [data]);

  return isLoading ? (
    <JobCardSkeleton />
  ) : (
    <Link to={`/job/${id}`} className="w-full max-w-[789px] border border-black-30 bg-white p-6 shadow-none transition-all hover:shadow-job-card">
      <div className="relative flex w-full flex-col gap-y-6 sm:flex-row sm:gap-x-6 sm:gap-y-0">
        <div className="relative h-14 w-14 flex-shrink-0 overflow-hidden rounded-full">
          <img
            src={owners[0]?.avatar_original_url === null ? PersonImage : owners[0]?.avatar_original_url}
            alt="Person Image"
            className="h-14 w-full object-contain"
          />
        </div>
        <div className="flex w-full max-w-[533px] flex-col items-start justify-between">
          <p className="mb-2 text-base text-neutral-50">
            By <span className="text-neutral-70">{owners[0]?.name}</span>
          </p>
          <h6 className="mb-3 text-large font-medium text-black-100 sm:text-head-six">{title}</h6>
          <div className="flex w-full flex-wrap gap-4">
            <div className="flex items-center gap-x-1">
              <JobIcon className="h-5 w-5" />
              <p className="text-small text-neutral-70 xl:text-base">{category === null ? '-' : category.name}</p>
            </div>
            <div className="flex items-center gap-x-1">
              <SalaryIcon className="h-5 w-5" />
              <p className="text-small text-neutral-70 xl:text-base">{salary === null ? '-' : salary}</p>
            </div>
            <div className="flex items-center gap-x-1">
              <PlaceIcon className="h-5 w-5" />
              <p className="text-small text-neutral-70 xl:text-base">{city === null ? '-' : city}</p>
            </div>
          </div>
        </div>
        <p className="absolute right-0 top-0 text-small text-neutral-50 xl:text-base">{moment(datePublished).format('MMM DD, YYYY')}</p>
      </div>
    </Link>
  );
};

JobCard.propTypes = {
  index: PropTypes.number,
  data: PropTypes.object
};

export default JobCard;
