import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card, { CardHeader } from './Components/Card';

const ContractType = ({ jobs, setFilterJobType, filterJobType }) => {
  const [contractType, setBusinessType] = useState([
    {
      id: '46781',
      name: 'Full Time',
      count: 0
    },
    {
      id: '46782',
      name: 'Contract',
      count: 0
    }
  ]);

  useEffect(() => {
    const uniqueCategory = [...new Set(jobs.map((item) => `${item?.job_type?.name}`))];
    const listJobSectors = uniqueCategory.map((uniqueItem) => {
      const filterCategory = jobs.filter(function (item) {
        if (`${item?.job_type?.name}` === uniqueItem) {
          return true;
        } else {
          return false;
        }
      });

      const count = filterCategory.length;
      return {
        id: `${filterCategory[0]?.job_type?.id}`,
        name: uniqueItem === 'undefined' ? 'Other' : uniqueItem,
        count
      };
    });

    const listJobSectorsSort = listJobSectors.sort((a, b) => b.count - a.count);
    // console.log(listJobSectorsSort, 'test lagi');

    setBusinessType((state) => {
      let temp = [...state];
      temp = temp.map((item, index) => {
        if (item.id === listJobSectorsSort[index]?.id) {
          item = listJobSectorsSort[index];
        }
        return item;
      });
      return [...temp];
    });
  }, [jobs]);

  const handleCheckbox = (e) => {
    setFilterJobType((state) => {
      let temp = [...state];
      if (temp.includes(e.target.value)) {
        temp = temp.filter((item) => item !== e.target.value);
      } else {
        temp = [...state, e.target.value];
      }
      return [...temp];
    });
  };

  return (
    <Card>
      <div className="border-b border-b-neutral-30 pb-4">
        <CardHeader>Contract type</CardHeader>
      </div>

      <div className="mt-4 flex flex-col gap-y-1">
        {contractType.map(({ id, name, count }, index) => {
          return (
            <div key={index} className="flex items-center justify-between py-1 sm:py-3">
              <div className="flex items-center gap-x-4">
                <input
                  id={`${name}-${index}`}
                  type="checkbox"
                  value={id}
                  className="border-nudes-600 hover:cursor-pointer focus:ring-0"
                  onClick={handleCheckbox}
                  checked={filterJobType.includes(id)}
                />
                <label htmlFor={`${name}-${index}`} className="text-medium text-black-100 hover:cursor-pointer">
                  {name}
                </label>
              </div>
              <p className="text-medium text-black-60">{count}</p>
            </div>
          );
        })}
      </div>
    </Card>
  );
};

ContractType.propTypes = {
  jobs: PropTypes.array,
  setFilterJobType: PropTypes.func
};

export default ContractType;
