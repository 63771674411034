import CallToAction from 'components/home/CallToAction';
import JobListing from 'components/jobs/JobListing';
import JobSearch from 'components/jobs/JobSearch';
import React from 'react';
import { Helmet } from 'react-helmet';

const Jobs = () => {
  return (
    <>
      <Helmet>
        <title>Find Industry Job - Real Estate Career & Property Management</title>
        <meta
          name="description"
          content="Find industry jobs at Industry Talent. Specializing in real estate, property management, and more job opportunities across Australia. Browse and apply today."
        />
        <meta name="keywords" content="industry job, real estate careers, property management australia" />
      </Helmet>
      <div className="overflow-hidden">
        <JobSearch />
        <JobListing />
        <CallToAction />
      </div>
    </>
  );
};

export default Jobs;
