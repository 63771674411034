import clsx from 'clsx';
import React from 'react';

const JobCardSkeleton = () => {
  return (
    <div className={clsx('animate-pulse', 'p-6', 'w-full', 'h-fit', 'max-w-[789px] border border-black-30 bg-neutral-20 shadow-recruitment-hero')}>
      <div className="relative flex w-full flex-col gap-6 sm:flex-row">
        <div className="h-14 w-14 flex-shrink-0 rounded-full bg-neutral-30"></div>
        <div className="flex w-full flex-col gap-y-2">
          <div className="h-6 w-full max-w-[121px] bg-neutral-30"></div>
          <div className="mb-4 h-6 w-full max-w-[303px] bg-neutral-30 sm:mb-0"></div>
          <div className="flex flex-wrap gap-4">
            <div className="h-6 w-full max-w-[100px] bg-neutral-30"></div>
            <div className="h-6 w-full max-w-[160px] bg-neutral-30 sm:max-w-[120px]"></div>
            <div className="h-6 w-full max-w-[130px] bg-neutral-30"></div>
          </div>
        </div>
        <div className="absolute right-0 top-0 ml-auto h-6 w-[104px] bg-neutral-30"></div>
      </div>
    </div>
  );
};

export default JobCardSkeleton;
