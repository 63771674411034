import React from 'react';

const Skeleton = () => {
  return (
    <section>
      <div
        className="max-w-[350px] rounded-lg bg-neutral-10 p-8"
        style={{
          boxShadow: '0px 15px 50px rgba(107, 124, 148, 0.08)'
        }}>
        <div className="animate-pulse">
          <div className="left-0 right-0 m-auto h-[88px] w-[88px] overflow-hidden rounded-full bg-[#EDF1F6]"></div>
          <div className="mt-6">
            <div className="h-7 w-[275px] rounded-lg bg-[#EDF1F6]"></div>

            <div className="mb-6 mt-4 rounded-lg border border-neutral-30 p-3">
              <div className="mb-3 h-5 w-full gap-x-4 rounded-lg bg-[#EDF1F6]" />

              <div className="mb-3 h-5 w-full gap-x-4 rounded-lg bg-[#EDF1F6]" />
            </div>
          </div>

          <div className="left-0 right-0 m-auto mt-6 h-5 w-[138px] rounded-lg bg-[#EDF1F6]" />

          <div className="button-l-medium text-primary-main mt-8 h-14 w-full rounded-[100px] bg-[#EDF1F6]" />
        </div>
      </div>
    </section>
  );
};

export default Skeleton;
