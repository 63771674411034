import Button from 'components/common/Button';
import Container from 'components/common/Container';
import Section from 'components/common/Section';
import React from 'react';
import Person from 'assets/start-hiring/hero-recruitment.png';
import PersonMobile from 'assets/start-hiring/hero-recruitment-mobile.png';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import SliderHiring from './SliderHiring';
import Grid from 'components/common/Grid';
import { Link } from 'react-router-dom';
const Recruitment = () => {
  const Specialicity = [
    'Recruitment Specialists',
    'Qualified candidates ',
    'Sourcing',
    'Interviewing and screening ',
    'Communication',
    'Negotiation ',
    'Onboarding '
  ];
  return (
    <Section id="recruitment-simplified" className="order-1 mt-[144px] sm:mt-[176px] xl:mt-[192px]">
      <Container className=" ">
        <Grid>
          <div className="col-span-full sm:col-span-6">
            <h1
              data-aos="fade-left"
              data-aos-delay="300"
              className="mb-6 text-head-three font-medium text-neutral-100 sm:text-[56px] sm:leading-[64px] xl:text-head-one">
              Recruitment Simplified
            </h1>
            <p data-aos="fade-left" data-aos-delay="500" className="mb-[40px] max-w-[598px] text-base text-black-60 sm:text-large xl:mb-14">
              Industry Talent are on a mission to challenge the traditional recruitment experience from resourcing through to onboarding for both our
              clients and candidates. With years of experience we have created a smooth process to ensure we are connecting the top 1% of talent with
              organisations that align with their culture, values and vision.{' '}
            </p>
            <Link to="/jobs" data-aos="fade-left" data-aos-delay="700" className="block w-full sm:max-w-[200px]">
              <Button isPrimary className="w-full sm:max-w-[200px] ">
                Apply Now
              </Button>
            </Link>
          </div>
          <div className="col-span-full sm:col-span-6">
            <div
              data-aos="fade-down-left"
              data-aos-delay="300"
              className="relative z-[1] ml-auto mt-[78px] h-[402px] w-[315px] bg-brand-other-surface sm:mt-[24px] sm:h-[504px] sm:w-[315px] xl:mt-[32px] xl:h-[504px] xl:w-[408px]">
              <div
                data-aos="fade-up-left"
                data-aos-delay="500"
                className="absolute z-[2] -ml-[12px] -mt-[30px] h-[416px] w-[311px] sm:-ml-[84px] sm:-mt-[24px] sm:h-[504px] sm:w-[375px] xl:-ml-[32px] xl:-mt-[32px] xl:h-[504px] xl:w-[408px]">
                <img
                  src={Person}
                  alt="Recruitment Simplified"
                  className="hidden h-[416px] w-full object-cover object-center sm:block sm:h-[504px] xl:h-[504px]"
                />
                <img
                  src={PersonMobile}
                  alt="Recruitment Simplified"
                  className="block h-[416px] w-full object-cover object-center sm:hidden sm:h-[504px] xl:h-[504px]"
                />
              </div>
              <div className="absolute z-[3] ml-4 mt-[218px] flex h-[224px] w-[272px] flex-col bg-white p-4  shadow-recruitment-hero sm:-ml-[68px] sm:mt-[269px] xl:-ml-[160px] xl:mt-[118px] xl:h-[372px] xl:w-[280px] xl:gap-y-3 xl:px-5 xl:py-6">
                {/* list dekstop */}
                {Specialicity.map((item, index) => (
                  <div
                    data-aos="fade-down"
                    data-aos-offset="-300"
                    data-aos-delay={300 + index * 100}
                    className="hidden bg-brand-other-surface px-4 py-2 xl:flex"
                    key={index}>
                    <CheckCircleIcon className="mr-[14px] h-[20px] w-auto text-brand-other-main" />
                    <p className="text-small font-medium text-black-90">{item}</p>
                  </div>
                ))}
                {/* list mobile */}
                <div className="block xl:hidden">
                  <SliderHiring />
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Container>
    </Section>
  );
};

export default Recruitment;
