import 'swiper/css';
import 'swiper/css/pagination';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard, Pagination } from 'swiper';
import ResidenIcon from 'assets/home/industries/real-estate.svg';
import RetailIcon from 'assets/home/industries/retail.svg';
import BuildingIcon from 'assets/home/industries/building.svg';
import CorporateIcon from 'assets/home/industries/corporate.png';
import FinanceIcon from 'assets/home/industries/finance.svg';
import ExecutiveIcon from 'assets/home/industries/executive.svg';
import PropertyIcon from 'assets/home/industries/property.svg';
import LinkItem from './LinkItem';
const SliderIndustries = () => {
  const dataArr = [
    {
      icon: ResidenIcon,
      title: 'Residential Real Estate'
    },
    {
      icon: RetailIcon,
      title: 'Retail and Commercial Real Estate ',
      className: 'bg-brand-other-surface '
    },
    {
      icon: CorporateIcon,
      title: 'Body Corporate'
    },
    {
      icon: FinanceIcon,
      title: 'Finance and Investment ',
      className: 'bg-brand-other-surface '
    },

    {
      icon: BuildingIcon,
      title: 'Building and Construction ',
      className: ''
    },
    {
      icon: PropertyIcon,
      title: 'Property Development',
      className: 'bg-brand-other-surface '
    },
    {
      icon: ExecutiveIcon,
      title: 'Executive Search',
      className: 'bg-brand-other-surface'
    }
  ];
  return (
    <div className="relative sm:hidden">
      <Swiper
        id="industries-slider"
        className="w-full "
        // style={{
        //   overflow: 'visible'
        // }}
        pagination={{
          el: '.industries-pagination',
          clickable: true,
          renderBullet: (index, className) => {
            return '<span class="' + className + '"></span>';
          }
        }}
        spaceBetween={32}
        slidesPerView={1}
        centeredSlides={true}
        onSwiper={(swiper) => console.log(swiper)}
        keyboard={true}
        loop={true}
        speed={1000}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true
        }}
        modules={[Autoplay, Keyboard, Pagination]}
        breakpoints={{
          640: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 3
          },
          1280: {
            slidesPerView: 3
          }
        }}>
        <SwiperSlide>
          <div className="flex flex-col gap-8">
            <LinkItem data={dataArr[0]} />
            <LinkItem data={dataArr[1]} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col gap-8">
            <LinkItem data={dataArr[2]} />
            <LinkItem data={dataArr[3]} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col gap-8">
            <LinkItem data={dataArr[4]} />
            <LinkItem data={dataArr[5]} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col gap-8">
            <LinkItem data={dataArr[6]} />
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="industries-pagination mt-8 flex justify-center sm:hidden"></div>
    </div>
  );
};

export default SliderIndustries;
