import AboutUs from 'components/home/About';
import CallToAction from 'components/home/CallToAction';
import GetInTouch from 'components/home/GetInTouch';
import Hero from 'components/home/Hero';
import Industries from 'components/home/Industries';
import Jobs from 'components/home/Jobs';
import React from 'react';
import { Helmet } from 'react-helmet';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Industry Talent: Leading Talent Recruitment Agency in Australia</title>
        <meta
          name="description"
          content="Find top talent for your real estate business with Industry Talent a leading recruitment agency. Contact us today to grow your team."
        />
        <meta name="keywords" content="real estate recruitment agency, talent agency, industry leading talent" />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "EmploymentAgency",
            "name": "Industry Talent",
            "image": "https://industrytalent.com.au/static/media/hero-mobile.cdbe3b2ac29623c47696.png",
            "@id": "",
            "url": "https://industrytalent.com.au/",
            "telephone": "(+61)0480 096 705",
            "priceRange": "$$$-$$$$",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "85 Commercial Road",
              "addressLocality": "Teneriffe",
              "addressRegion": "QLD",
              "postalCode": "4005",
              "addressCountry": "AU"
            } ,
            "sameAs": [
              "https://www.linkedin.com/company/industry-talent-au/",
              "https://www.instagram.com/industrytalentau/",
              "https://www.facebook.com/IndustryTalentAU"
            ] 
          }`}
        </script>
      </Helmet>
      <div className="overflow-hidden">
        <Hero />
        <AboutUs />
        <Jobs />
        <Industries />
        <GetInTouch />
        <CallToAction />
      </div>
    </>
  );
};

export default Home;
