import React from 'react';
import HeroBackground from 'assets/home/hero.png';
import HeroBackgroundTablet from 'assets/home/hero-tablet.png';
import HeroBackgroundMobile from 'assets/home/hero-mobile.png';
import Container from 'components/common/Container';
import Section from 'components/common/Section';

import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  // const store = new SteinStore('https://api.steinhq.com/v1/storages/63bd0a5dd27cdd09f0d6d828');
  const [value, setValue] = React.useState([]);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    navigate('/start-hiring');
  };
  return (
    <Section id="hero" className="relative h-[616px] overflow-hidden sm:h-[608px] xl:h-[716px]">
      <Toaster />
      <img src={HeroBackground} className="hidden h-full w-full object-cover xl:block" alt="Hero" />
      <img src={HeroBackgroundTablet} className="hidden h-full w-full object-cover sm:block xl:hidden" alt="Hero" />
      <img src={HeroBackgroundMobile} className="block h-full w-full object-cover sm:hidden" alt="Hero" />
      <div className="absolute bottom-0 left-0 right-0 top-0 bg-hero ">
        <Container className="mt-[144px] grid grid-cols-4 gap-x-4 sm:mt-[176px] sm:grid-cols-9 xl:mt-[208px] xl:grid-cols-12 xl:gap-x-8">
          <div className="col-span-4 flex flex-col sm:col-span-7 sm:col-start-2  xl:col-span-8 xl:col-start-3">
            <h1
              data-aos="fade-up"
              data-aos-delay="300"
              className="mb-[24px] text-center font-ranade text-head-four font-medium  text-white sm:text-[56px] sm:leading-[64px] xl:text-head-one">
              Finding, Connecting, Building Success
            </h1>
          </div>
          <div className=" col-span-4 flex flex-col sm:col-span-7 sm:col-start-2 xl:col-span-6 xl:col-start-4">
            <p
              data-aos="fade-up"
              data-aos-delay="300"
              className="mx-auto mb-[40px] w-full max-w-[598px] text-center text-large text-white xl:w-auto xl:max-w-none">
              We offer a personal, concierge-style, unique recruitment service for businesses who want the best talent in their team.
            </p>
            <form onSubmit={handleSubmit} className="flex flex-col justify-center sm:flex-row" data-aos="fade-up" data-aos-delay="500">
              <input
                type="email"
                className=" text-black mb-4 h-[56px] w-full border border-transparent bg-neutral-10 px-5 py-4 text-medium font-normal leading-[21.6px] placeholder-neutral-50 placeholder:font-switzer focus:border-brand-primary-main focus:ring-0 focus:ring-offset-0 sm:mb-0 sm:mr-4 sm:w-[418px] xl:w-[420px] "
                placeholder="Enter your email..."
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <button
                type="submit"
                className="flex h-[56px] w-full max-w-none items-center justify-center bg-brand-other-main text-medium text-white transition-all duration-200 hover:bg-brand-primary-main sm:max-w-[164px]">
                Start Hiring
              </button>
            </form>
          </div>
        </Container>
      </div>
    </Section>
  );
};

export default Hero;
