import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Layout from 'layout';
import Home from 'pages/Home';
import Hiring from 'pages/Hiring';
import About from 'pages/About';
import Contact from 'pages/Contact';
import Jobs from 'pages/Jobs';
import JobDetails from 'pages/JobDetails';
import ScrollToTop from 'utils/ScrollToTop';

const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="start-hiring" element={<Hiring />} />
            <Route path="about-us" element={<About />} />
            <Route path="contact-us" element={<Contact />} />
            <Route path="jobs" element={<Jobs />} />
            <Route path="job/:id" element={<JobDetails />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default Router;
