import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';

const InputResume = ({ setSelectedFile }) => {
  const [url, setUrl] = useState('');

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);

    const files = event.target.files;
    files.length > 0 && setUrl(URL.createObjectURL(files[0]));
  };

  return (
    <div>
      <label className="mb-2 text-xsmall font-medium text-black-100">
        Resume
        <span className="text-danger-500">*</span>
      </label>

      <div
        className={clsx(
          'relative mt-2 h-[240px] w-full rounded-2xl border border-dashed border-neutral-30 bg-neutral-10',
          'flex flex-col items-center justify-center'
        )}>
        {url ? (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.7.570/es5/build/pdf.worker.js">
            <div className="h-full w-full">
              <Viewer fileUrl={url} />
            </div>
          </Worker>
        ) : (
          <>
            <div>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.3998 3.19995C12.6325 3.19995 11.1998 4.63264 11.1998 6.39995V19.2C11.1998 20.9673 12.6325 22.4 14.3998 22.4H23.9998C25.7671 22.4 27.1998 20.9673 27.1998 19.2V10.2627C27.1998 9.414 26.8627 8.60007 26.2625 7.99995L22.3998 4.13721C21.7997 3.53709 20.9858 3.19995 20.1371 3.19995H14.3998Z"
                  fill="#C4C4C4"
                />
                <path
                  d="M4.7998 12.8C4.7998 11.0326 6.23249 9.59995 7.9998 9.59995V25.5999H20.7998C20.7998 27.3673 19.3671 28.7999 17.5998 28.7999H7.9998C6.23249 28.7999 4.7998 27.3673 4.7998 25.5999V12.8Z"
                  fill="#C4C4C4"
                />
              </svg>
            </div>
            <p className="mt-4 text-small font-medium text-black-50 sm:text-base">Upload Your Resume</p>
            <p className="mt-1 text-xsmall text-black-40 antialiased sm:text-small">
              {' '}
              Drop your file or click{' '}
              <span
                onClick={() => document.getElementById('selectedFile-resume').click()}
                className="cursor-pointer font-semibold text-brand-primary-main">
                here
              </span>{' '}
              to browse file{' '}
            </p>
          </>
        )}
        <input type="file" id="selectedFile-resume" className="absolute opacity-0" accept=".pdf" onChange={handleFileSelect} required />
      </div>
    </div>
  );
};

InputResume.propTypes = {
  setSelectedFile: PropTypes.func
};

export default InputResume;
