import clsx from 'clsx';
import React from 'react';

const Button = ({ onClick, children, className, isPrimary, isMedium, isLarge, ...rest }) => {
  return (
    <button
      className={clsx(
        'flex items-center justify-center py-4 font-ranade text-medium font-medium transition-all',
        isPrimary && 'bg-brand-primary-main text-white hover:bg-brand-other-main',
        isMedium && 'text-medium',
        isLarge && 'text-large',
        className
      )}
      onClick={onClick}
      {...rest}>
      {children}
    </button>
  );
};

export default Button;
