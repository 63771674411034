import Competitive from 'components/hiring/Competitive';
import Recruitment from 'components/hiring/Recruitment';
import YourTeam from 'components/hiring/YourTeam';
import CallToAction from 'components/home/CallToAction';
import React from 'react';
import { Helmet } from 'react-helmet';

const Hiring = () => {
  return (
    <>
      <Helmet>
        <title>Property Management Australia - Start Hiring Today</title>
        <meta
          name="description"
          content="A unique construction staffing agency in Australia. Providing top talent and career opportunities for property management & construction jobs. Find more!"
        />
        <meta name="keywords" content="construction staffing agency, property management, construction job, property management australia" />
      </Helmet>
      <div className="flex w-full flex-col">
        <Recruitment />
        <Competitive />
        <YourTeam />
      </div>
    </>
  );
};

export default Hiring;
