import clsx from 'clsx';
import React from 'react';

const InputPrimary = ({ className, name, value, onChange, type, placeholder, ...props }) => {
  return (
    <input
      {...props}
      className={clsx(
        'h-[48px] border border-black-30 px-4 py-3 text-small text-brand-primary-main focus:border-brand-primary-main focus:ring-0',
        className
      )}
      name={name}
      id={name}
      value={value}
      onChange={onChange}
      type={type}
      placeholder={placeholder}
    />
  );
};

export default InputPrimary;
