import HeroWhy from 'components/about/HeroWhy';
import Krystal from 'components/about/Krystal';
import Testimonial from 'components/about/Testimonial';
import Value from 'components/about/Value';
import CallToAction from 'components/home/CallToAction';
import React from 'react';
import { Helmet } from 'react-helmet';

const About = () => {
  return (
    <>
      <Helmet>
        <title>Leading Real Estate Recruitment Australia | Industry Talent</title>
        <meta
          name="description"
          content="Learn about Industry Talent, a leading recruitment company in Australia. Specializing in property recruitment with the best job opportunities. Discover more!"
        />
        <meta name="keywords" content="real estate recruitment agency, property recruitment specialists, recruitment company Australia" />
      </Helmet>
      <div className="overflow-hidden">
        <HeroWhy />
        <Value />
        <Krystal />
        <Testimonial />
        <CallToAction />
      </div>
    </>
  );
};

export default About;
