import React from 'react';
import PropTypes from 'prop-types';

import Next from './Next';
import Previous from './Previous';
import { usePagination, DOTS } from 'utils/usePagination';
import clsx from 'clsx';

const Pagination = ({ onPageChange, totalCount, totalPages, siblingCount = 0, currentPage, pageSize, className }) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    currentPage < totalPages && onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    currentPage > 1 && onPageChange(currentPage - 1);
  };

  // const lastPage = paginationRange[paginationRange.length - 1]

  return (
    <div className="flex w-full items-center justify-between">
      <Previous onClick={onPrevious} />
      <div className="mx-auto flex gap-x-[2px] lg:gap-x-[2px]">
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <button key={index} className="button-l-bold h-10 w-10 rounded-full bg-neutral-10 text-neutral-60">
                ...
              </button>
            );
          }

          return (
            <button
              key={index}
              className={clsx('flex h-10 w-10 items-center justify-center', {
                'bg-neutral-20 text-black-100': pageNumber === currentPage,
                'bg-white text-neutral-60': pageNumber !== currentPage
              })}
              onClick={() => onPageChange(pageNumber)}>
              {pageNumber}
            </button>
          );
        })}
      </div>
      <Next onClick={onNext} />
    </div>
  );
};

Pagination.propTypes = {
  onPageChange: PropTypes.func,
  totalCount: PropTypes.number,
  totalPages: PropTypes.number,
  siblingCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  className: PropTypes.string
};

export default Pagination;
