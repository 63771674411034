import { clientRoot } from 'api';
import Description from 'components/job-details/Description';
import Hero from 'components/job-details/Hero';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';

const JobDetails = () => {
  const { id } = useParams();

  const [job, setJob] = useState({});

  useEffect(() => {
    clientRoot.get(`job?id=${id}`).then((response) => {
      setJob(response.data);
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>{job.title || 'Job Title'} | Real Estate Career & Property Management</title>
        <meta
          name="description"
          content="Find industry jobs at Industry Talent. Specializing in real estate, property management, and more job opportunities across Australia. Browse and apply today."
        />
        <meta name="keywords" content="real estate careers, property management australia" />
      </Helmet>
      <div className="overflow-hidden">
        <Hero data={job} />
        <Description data={job} />
      </div>
    </>
  );
};

export default JobDetails;
