import clsx from 'clsx';
import React from 'react';

const Section = ({ children, className, id }) => {
  return (
    <section className={clsx('w-full', className)} id={id}>
      {children}
    </section>
  );
};

export default Section;
