import Container from 'components/common/Container';
import React from 'react';
import { ReactComponent as Logo } from 'assets/logo-footer.svg';
import { ReactComponent as FacebookIcon } from 'assets/footer/facebook.svg';
import { ReactComponent as InstagramIcon } from 'assets/footer/instagram.svg';
import { ReactComponent as LinkedinIcon } from 'assets/footer/linkedin.svg';
import { ReactComponent as XrecruiterLogo } from 'assets/footer/xrecruiter.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="w-full bg-black-100 pb-[88px] pt-12 sm:pb-[64px] sm:pt-[80px] xl:pb-[104px] xl:pt-[96px]">
      <Container>
        <div className="mb-[42px] grid w-full grid-cols-4 gap-x-8 sm:mb-[72px] sm:grid-cols-12">
          <div className="col-span-full sm:col-span-3">
            <div className="mb-10 flex flex-col gap-6 sm:mb-0">
              <Link to="/">
                <Logo className="h-[48px] w-auto" />
              </Link>
              <div className="flex gap-x-6">
                <a target="_blank" href="https://www.facebook.com/IndustryTalentAU" rel="noreferrer">
                  <FacebookIcon className="h-6 w-6" />
                </a>
                <a target="_blank" href="https://www.instagram.com/industrytalentau/" rel="noreferrer">
                  <InstagramIcon className="h-6 w-6" />
                </a>
                <a target="_blank" href="https://www.linkedin.com/company/industry-talent-au/" rel="noreferrer">
                  <LinkedinIcon className="h-6 w-6" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-span-full flex justify-end gap-x-[72px] text-small text-white sm:col-span-8 sm:col-end-13 sm:gap-x-[80px] xl:col-span-7 xl:col-end-13 xl:gap-x-[120px]">
            <div className="flex flex-col ">
              <p className="mb-4 text-large font-medium">Explore</p>
              <div className="mb-8 flex flex-col gap-y-3 sm:mb-0">
                <Link to="/start-hiring" className="text-small text-white-rgba-80">
                  Start Hiring
                </Link>
                <Link to="/jobs" className="text-small text-white-rgba-80">
                  Current Jobs
                </Link>
                <Link to="/about-us" className="text-small text-white-rgba-80">
                  About Us
                </Link>
                <Link to="/contact-us" className="text-small text-white-rgba-80">
                  Contact Us
                </Link>
              </div>
              <div className="flex flex-col gap-y-3 sm:hidden">
                <p className="text-large font-medium">Brisbane</p>
                <p className="text-large font-medium">Sydney</p>
                <p className="text-large font-medium">Melbourne</p>
              </div>
            </div>
            <div className="flex flex-col ">
              <p className="mb-4 text-large font-medium">Industries</p>
              <div className="flex flex-col gap-y-3">
                <Link to="/start-hiring" className="text-small text-white-rgba-80">
                  Accounting
                </Link>
                <Link to="/start-hiring" className="text-small text-white-rgba-80">
                  Residential and Commercial Real Estate{' '}
                </Link>
                <Link to="/start-hiring" className="text-small text-white-rgba-80">
                  Body Corporate
                </Link>
                <Link to="/start-hiring" className="text-small text-white-rgba-80">
                  Finance and Investment{' '}
                </Link>
                <Link to="/start-hiring" className="text-small text-white-rgba-80">
                  Building and Construction{' '}
                </Link>
                <Link to="/start-hiring" className="text-small text-white-rgba-80">
                  Property Development{' '}
                </Link>
                <Link to="/start-hiring" className="text-small text-white-rgba-80">
                  Executive Search{' '}
                </Link>
              </div>
            </div>
            <div className="hidden flex-col gap-y-3 sm:flex">
              <p className="text-large font-medium">Brisbane</p>
              <p className="text-large font-medium">Sydney</p>
              <p className="text-large font-medium">Melbourne</p>
            </div>
          </div>
        </div>
        <div className="justify-between border-t border-white border-opacity-30 pt-[40px] sm:flex">
          <p className="w-full text-center text-small font-medium tracking-wide text-white-rgba-88 sm:text-left">
            © Industry Talent Copyright 2022. <br className="block sm:hidden" /> All Rights Reserved.
          </p>
          <div className="mt-6 flex w-full justify-center sm:mt-0 sm:justify-end">
            <p className="mr-4 items-center text-center text-small font-medium tracking-wide text-white-rgba-88">Powered by:</p>
            <a href="https://xrecruiter.io/" target="_blank" rel="noreferrer">
              <XrecruiterLogo />
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
