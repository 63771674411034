import clsx from 'clsx';
import React from 'react';

const Anchor = ({ isHover }) => {
  return (
    <a href="#" className={clsx('flex items-center self-start text-xsmall  text-black-100 sm:self-end')}>
      View Details
      <ArrowIcon isHover={isHover} />
    </a>
  );
};

const ArrowIcon = ({ isHover }) => {
  return (
    <svg width="16" height="16" className="ml-1" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 12L12 4M12 4H6.66667M12 4V9.33333" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Anchor;
