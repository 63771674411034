import Container from 'components/common/Container';
import React, { useState } from 'react';
import Person from 'assets/home/about-person.png';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Label from 'components/common/Label';
import { ReactComponent as CircleDekstop } from 'assets/home/circle-desktop.svg';
const AboutUs = () => {
  const [linkIsHover, setLinkIsHover] = useState('');
  const isHover = linkIsHover === 'access';
  return (
    <section id="about-us" className="relative w-full overflow-hidden">
      <Container className="relative pb-[40px] pt-[48px] sm:pb-[64px] sm:pt-[80px] xl:py-[96px]">
        <div data-aos="fade-down-left" data-aos-delay="400" data-aos-duration="1500" className="absolute right-0 top-0 block xl:-right-[84px]">
          <CircleDekstop />
        </div>
        <div className="grid h-full max-h-[1226px]  grid-cols-4 gap-x-8  sm:max-h-[654px] sm:grid-cols-12 xl:max-h-[812px] ">
          <div className="relative order-2 col-span-full mt-[100px] sm:order-1 sm:col-span-6 sm:mt-0">
            <div
              data-aos="fade-up-right"
              data-aos-delay="300"
              className="absolute left-[-24px] top-[-40px] h-[590px] w-[203px] bg-brand-other-surface sm:-bottom-[64px] sm:-left-[56px] sm:-top-[80px] sm:h-[654px] sm:w-[292px] xl:-bottom-[96px] xl:-left-[120px] xl:-top-[96px] xl:h-[812px]  xl:w-[499px]"></div>
            <div
              data-aos="fade-down-right"
              data-aos-delay="500"
              className="relative z-[1] h-[510px] w-auto sm:h-[510px] sm:w-[403px] xl:h-[620px] xl:w-[568px]">
              <img src={Person} alt="Person About us" className="h-[510px] w-full object-cover xl:h-[620px]" />
            </div>
          </div>
          <div className="order-1 col-span-full flex items-center justify-start sm:order-2 sm:col-span-6 ">
            <Link to="/about-us">
              <div className="relative">
                {/* Box Shadow */}
                <div className={clsx('absolute left-4 top-6 bg-neutral-100 bg-opacity-[0.26] blur-[80px]', 'transition-all duration-700')}></div>

                {/* Background */}
                <div className={clsx('absolute left-0 top-0 h-full w-full  overflow-hidden')}>
                  <div
                    className={clsx(
                      'relative h-[200%] w-[200%] bg-brand-primary-main',
                      'left-[-50%] origin-bottom-left transform rounded-[50%] duration-[350ms]',
                      'transition-all ease-out',
                      isHover ? 'translate-y-[-7.5%] scale-100' : 'translate-y-0 scale-0'
                    )}></div>
                </div>

                {/* Content */}
                <div className={clsx('relative flex min-h-[280px] flex-col items-start justify-between ', 'transform duration-500')}>
                  <div className="mb-9 flex flex-col items-start sm:mb-0">
                    <h5
                      data-aos="fade-left"
                      data-aos-delay="500"
                      className={clsx(
                        '  font-ranade text-head-six font-medium sm:mb-9 xl:text-head-five',
                        'w-full max-w-[327px] transform text-black-100 duration-500 sm:max-w-[568px]'
                      )}>
                      At Industry Talent we match highly skilled talent to businesses culture, values, and vision to ensure a strong fit. Our client’s
                      and candidates’ needs are our needs. With a proven track record for quality service we understand that finding the best talent
                      is pivotal to success.
                    </h5>
                  </div>
                  <button
                    data-aos="fade-down-left"
                    data-aos-delay="600"
                    className={clsx('mt-auto flex items-center', isHover ? 'text-neutral-10' : 'text-black-100')}>
                    Read More
                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9.59766 7.30688L15.3205 7.30688L15.3205 13.0297"
                        stroke={isHover ? 'white' : '#1E293B'}
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.30664 15.3206L15.2404 7.38682"
                        stroke={isHover ? 'white' : '#1E293B'}
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AboutUs;
