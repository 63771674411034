import { useMemo } from 'react';

import JobLocation from './JobLocation';
import ContractType from './ContractType';
import BusinessSector from './BusinessSector';

const useFilterJob = (jobs, filterBusinessType, filterJobType, filterJobLocation, searchQuery, queryKeyword) => {
  return useMemo(() => {
    const uniqueCity = [...new Set(jobs.map((item) => `${item?.city}`))];
    const listJobCity = uniqueCity.map((uniqueItem) => {
      const filterCity = jobs.filter(function (item) {
        if (`${item?.city}` === uniqueItem) {
          return true;
        } else {
          return false;
        }
      });

      const count = filterCity.length;
      return {
        name: uniqueItem === 'null' ? 'Other' : uniqueItem,
        count
      };
    });

    const listJobCitySort = listJobCity.sort((a, b) => b.count - a.count);
    const listJobCityPrimary = listJobCitySort.slice(0, 9);
    const listCityName = listJobCityPrimary.map((item) => item.name);

    return jobs.filter((job) => {
      // create multiple filter
      const filterBusinessTypeResult = filterBusinessType.length ? filterBusinessType.includes(`${job?.category?.id}`) : true;
      const filterJobTypeResult = filterJobType.length ? filterJobType.includes(`${job.job_type?.id}`) : true;
      const filterJobLocationResult = filterJobLocation.length
        ? filterJobLocation.includes(job.city)
          ? true
          : filterJobLocation.includes('Other') && !listCityName.includes(job.city)
        : true;
      const searchQueryResult = searchQuery.title
        ? job.title?.toString().toLowerCase().includes(searchQuery.title.toLowerCase()) ||
          job.city?.toString().toLowerCase().includes(searchQuery.title.toLowerCase()) ||
          job.business_sector?.toString().toLowerCase().includes(searchQuery.title.toLowerCase()) ||
          job.job_type?.name?.toString().toLowerCase().includes(searchQuery.title.toLowerCase())
        : true;
      const searchQueryLocationResult = searchQuery.location
        ? job.city.toString().toLowerCase().includes(searchQuery.location.toLowerCase()) ||
          job?.macro_address?.toString().toLowerCase().includes(searchQuery.location.toLowerCase())
        : true;
      const searchQueryKeywordResult = queryKeyword
        ? job.title?.toString().toLowerCase().includes(queryKeyword.toLowerCase()) ||
          job.city?.toString().toLowerCase().includes(queryKeyword.toLowerCase()) ||
          job.business_sector?.toString().toLowerCase().includes(queryKeyword.toLowerCase()) ||
          job.job_type?.name?.toString().toLowerCase().includes(queryKeyword.toLowerCase()) ||
          job.company?.name?.toString().toLowerCase().includes(queryKeyword.toLowerCase())
        : true;
      return (
        filterBusinessTypeResult &&
        filterJobTypeResult &&
        filterJobLocationResult &&
        searchQueryResult &&
        searchQueryLocationResult &&
        searchQueryKeywordResult
      );
    });
  }, [jobs, filterBusinessType, filterJobType, filterJobLocation, searchQuery]);
};

export { useFilterJob, JobLocation, ContractType, BusinessSector };
